<template>
  <sub-page
      :title="$t('Newsletter')"
      v-model="$store.state.settings.pageData"
      icon="mdi-email-newsletter"

      :no-content-condition="items.length === 0"
      no-content-text="documentflow.document.no_content"
      fill-height

      :filters="filtersSelect"
  >
    <template #default>
      <ws-data-table
          class="mt-5"
          :items="itemsFiltered"
          :headers="headers"
          :search="$store.state.settings.filtersData.search"
          :row-action="($event) => openDocumentDialog($event)"
          :context-actions-select="documentActionsSelect"
          @contextAction="($event , item) => documentAction($event,item)"

      >

        <template #item.date="{item}">
          <h5 class="text-no-wrap">{{ MONTH_DAY_TIME(item.date , false) }}</h5>
        </template>

        <template #item.name="{item}">
          <h5 style="min-width: 300px" :style="`color : ${wsACCENT}`">{{ item.name }}</h5>
        </template>

        <template #item.structure_entity_name="{item}">
          <h5 class="shortenText" >{{ item.structure_entity_name }}</h5>
        </template>

        <template #item.code="{item}">
          <h5 class="text-no-wrap" >{{ item.code }}</h5>
        </template>

        <template #item.is_signed="{item}">
          <div class="d-flex justify-center">
            <v-icon v-if="!item.is_declined" :color="item.is_signed ? wsACCENT : null">mdi-check-decagram</v-icon>
            <v-icon v-else :color="wsATTENTION" >mdi-alert</v-icon>
          </div>
        </template>

        <template #item.action="{item , hover}">
          <div style="width : 40px">
            <ft-select
                @input="documentAction($event , item)"
                :items="documentActionsSelect"
            >
              <v-btn v-if="hover" icon :color="wsACCENT">
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </ft-select>
          </div>

        </template>

      </ws-data-table>
    </template>

    <template #dialog>

      <document-dialog
          v-if="displayDocumentDialog"
          v-model="displayDocumentDialog"
          :document="selectedDocument"
      />


    </template>


  </sub-page>
</template>

<script>
import {mapActions} from "vuex";
import documentDialog from "@/components/pages/businessDashboard/businessDocumentflow/UI/documentDialog";
export default {
  name: "Tasks",
  components : {
    documentDialog
  },
  data() {
    return {
      items : [],
      displayDialog : false,
      entityData : {
        signators : [],
        aprovals : []
      },
      documentTypesSelect : [],
      documentTypesData : {},
      documentDepartmentsSelect : [],
      hrStructureEntitiesSelect : [],
      managersSelect : [],
      newEntity : false,
      newSignator : null,
      updateTrigger : 0,
      file : {},
      originalFile : {},

      nameError : null,
      typeError : null,
      codeError : null,
      dateError : null,
      signatorError : null,
      fileError : null,
      displayDocumentDialog : false,
      selectedDocument : {}
    }
  },
  computed : {
    headers() {
      return [
        {  value : 'is_signed' , width : 10 , sortable : false },
        { text : this.$t('Date') , value : 'date' , width : 10 },
        { text : this.$t('Name') , value : 'name' },
        { text : this.$t('StructureEntity') , value : 'structure_entity_name' , width : 200 },
        { text : this.$t('Type') , value : 'type_name' , width : 60 },
        { text : this.$t('Code') , value : 'code' , width : 10 },
        { text : this.$t('Owner') , value : 'owner_name' , width : 10 },
        { value : 'public_key' , width : 10 , align : 'd-none' },
        { value : 'action' , width : 50 },
      ]
    },
    documentActionsSelect() {
      return [
        { text : this.$t('Open') ,  value : 'view' },
        { text : this.$t('Download') , value : 'download' },
      ]
      // { text : this.$t('Delete') , value : 'delete' },
    },
    documentTypesSelectFiltered() {
      let items = this.COPY(this.documentTypesSelect)

      if ( this.entityData.structure_entity_id ) {
        items = items.filter(el=> el.categories.includes(this.entityData.structure_entity_id))
      }

      return items
    },
    itemsFiltered() {
      let items = this.COPY(this.items)

      if ( this.$store.state.settings.filtersData.structure_entity_id ) {
        items = items.filter(el => el.structure_entity_id === this.$store.state.settings.filtersData.structure_entity_id )
      }

      if ( this.$store.state.settings.filtersData.type ) {
        items = items.filter(el => el.type === this.$store.state.settings.filtersData.type )
      }

      if ( this.$store.state.settings.filtersData.owner_id ) {
        items = items.filter(el => el.owner_id === this.$store.state.settings.filtersData.owner_id )
      }

      if ( [true,false].includes( this.$store.state.settings.filtersData.is_signed ) ) {
        if ( this.$store.state.settings.filtersData.is_signed === true ) {
          items = items.filter(el => el.is_signed === true )
        } else {
          items = items.filter(el => !el.is_signed  )
        }
      }

      return items
    },
    managersSelectFiltered() {
      let items = this.COPY(this.managersSelect)
      if ( !this.entityData.signators ) {
        return items
      }
      return items.filter(el => !(this.entityData.signators.map(el => el.user_id).includes(el.value) || this.entityData.aprovals.map(el => el.user_id).includes(el.value) )|| this.newSignator === el.value)
    },
    filtersSelect(){
      return [
        { text : this.$t('StructureEntity')  , value : 'structure_entity_id' , type : 'select' , items : this.hrStructureEntitiesSelect },
        { text : this.$t('Type')  , value : 'type' , type : 'select' , items : this.documentTypesSelect },
        { text : this.$t('IsSigned')  , value : 'is_signed' , type : 'select' , items : [
            { text : this.$t('IsSigned') , value : true },
            { text : this.$t('NotSigned'), value : false }
        ]},
        { text : this.$t('Owner')  , value : 'owner_id' , type : 'select' , items : this.managersSelect },
      ]
    },
  },
  methods : {
    ...mapActions('documentflow', [
      'ADD_EDIT_DOCUMENT',
      'GET_NEWSLETTER_DOCUMENTS'
    ]),
    ...mapActions('upload', [ 'GET_PRIVATE_FILE' ]),

    checkParentDocument(code) {
      console.log('Check parent ajax' + code)
    },

    documentAction(action , item) {
      switch(action) {
        case 'view' : return this.openDocumentDialog(item)
        case 'download' : return this.downloadFile(item.file_data)
        case 'delete' : return this.deleteDocument(item)
      }
    },
    openDocumentDialog(event) {
      this.selectedDocument = event
      this.displayDocumentDialog = true
    },
    deleteDocument() {
      this.notify('delete')
    },


    handleStructureChange() {
      this.structureError = null
      this.entityData.type = null
      this.entityData.signators = []
      this.entityData.aprovals = []
    },
    handleTypeChange() {
      this.typeError = null
      let type = this.documentTypesSelect.find(el => el.value === this.entityData.type)
      if ( !this.entityData.type || !type ) {
        this.entityData.signators = []
        this.entityData.aprovals = []
        return
      }
      if ( this.documentTypesData && this.documentTypesData[this.entityData.type] ) {
        let typeDataAll = this.documentTypesData[this.entityData.type]
        if ( typeDataAll && typeDataAll.length > 0 ) {
          let typeData = typeDataAll.find(el => el.structure_entity_id === this.entityData.structure_entity_id )
          if ( typeData ) {
            this.entityData.signators = typeData.signators
            this.entityData.aprovals = typeData.aprovals
          }
        }
      }

    },

    async downloadFile(item) {

      let result = await this.GET_PRIVATE_FILE(item.uuid)
      if (!result) { return this.notify('File Not found') }
      var a = document.createElement('a');
      a.href = result.url;
      a.download = item.name;
      document.body.appendChild(a);
      a.click();
      a.remove();
    },

    getUser(value) {
      let user = this.managersSelect.find(el => el.value === value)
      if ( user ) {
        return user
      }
      return {}
    },
    getUserName(value) {
      let user = this.managersSelect.find(el => el.value === value)
      if ( user ) {
        return user.text
      }
      return ''
    },
    removeSignator(value , isAproval) {
      this.updateTrigger++
      if ( !isAproval ) {
        this.entityData.signators = this.entityData.signators.filter(el => el.user_id !== value)
      } else {
        this.entityData.aprovals = this.entityData.aprovals.filter(el => el.user_id !== value)
      }

    },
    addSignator(value , isAproval = false) {
      this.signatorError = null

      let user = this.getUser(value)

      let data = {
        user_id : value
      }
      if ( user.positions_select && user.positions_select.length > 0 ) {
        data.position_id = user.positions_select[0].value
      }

      if ( isAproval) {
        this.entityData.aprovals.push(data)
      } else {
        this.entityData.signators.push(data)
      }
      this.newSignator = null
      this.updateTrigger++
    },
    deleteFile(original) {
      if ( !original )  {
        this.file = {}
        this.entityData.file = null
        this.entityData.file_name = null
      } else {
        this.originalFile = {}
        this.entityData.original_file = null
        this.entityData.original_file_name = null
      }

    },
    addFile(file ,original = false) {
      this.fileError = null

      if ( !original )  {
        this.file = file
        this.entityData.file = file.uuid
        this.entityData.file_name = file.name
      } else {
        this.originalFile = file
        this.entityData.original_file = file.uuid
        this.entityData.original_file_name = file.name
      }

      this.entityData = this.COPY(this.entityData)
    },
    async addEditDocument() {

      if ( !this.entityData.name ) {
        this.nameError = true
        this.notify(this.$t('documentflow.document.name_error') , 'warning')
        return
      }
      if ( !this.entityData.type ) {
        this.typeError = true
        this.notify(this.$t('documentflow.document.type_error') , 'warning')
        return
      }
      if ( !this.entityData.code ) {
        this.codeError = true
        this.notify(this.$t('documentflow.document.code_error') , 'warning')
        return
      }

      if ( !this.entityData.date ) {
        this.dateError = true
        this.notify(this.$t('documentflow.document.date_error') , 'warning')
        return
      }

      if ( this.entityData.signators.length === 0 ) {
        this.signatorError = true
        this.notify(this.$t('documentflow.document.signator_error') , 'warning')
        return
      }

      if ( !this.entityData.file) {
        this.fileError = true
        this.notify(this.$t('documentflow.document.file_error') , 'warning')
        return
      }

      let result = await this.ADD_EDIT_DOCUMENT(this.entityData)
      if ( !result )  {
        this.ERROR()
        return
      }
      if ( this.newEntity) {
        this.items.unshift(result)
      } else {
        let index = this.items.findIndex(el => el.uuid === result.uuid)
        this.items[index] = this.COPY(result)
        this.items = this.COPY(this.items)
      }
      this.displayDialog = false
    },
    openNewDocument() {
      this.nameError = null
      this.typeError = null
      this.codeError = null
      this.dateError = null
      this.signatorError = null
      this.fileError = null

      this.newSignator = null
      this.newEntity = true
      this.entityData = this.COPY( {
        signators : [],
        aprovals : []
      })
      this.displayDialog = true
    },
    openEditDocument(item) {
      this.nameError = null
      this.typeError = null
      this.codeError = null
      this.dateError = null
      this.signatorError = null
      this.fileError = null

      this.newEntity = false
      this.entityData = this.COPY(item)
      this.displayDialog = true
    },

    // technical

    async initPage() {
      let result = await this.GET_NEWSLETTER_DOCUMENTS()
      if ( !result )  {
        this.ERROR()
      }
      result.items.reverse()
      this.items = result.items
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>